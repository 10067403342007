.cmn--btn {
	font-family: var(--heading-fonts);
	position: relative;
	color: var(--white-clr);
	border-radius: 3px;
	width: auto;
	font-weight: 500;
	text-transform: capitalize;
	outline: none;
	border: none;
	padding: 10px 25px;
	overflow: hidden;
	background: var(--base-clr);
	text-decoration: none;
	border: 2px solid var(--base-clr, 0.3);
	@include transition($transition);
	text-align: center;
	&:hover,
	&.btn-outline {
		color: var(--base-clr);
		background: transparent;
		border-color: var(--base-clr);
		.round-effect {
			&::before {
				background: var(--base-clr);
			}
			i {
				color: var(--white-clr);
			}
		}
	}
	&.btn-outline {
		&:hover {
			background: var(--base-clr);
			color: $white;
		}
	}
	.round-effect {
		position: relative;
		margin-left: 15px;
		&::before {
			width: 25px;
			height: 25px;
			@include border-radius(50%);
			background: rgba(var(--dark-rgb), 0.3);
			@extend %center;
		}
		i {
			position: relative;
			z-index: 1;
		}
	}
	&.btn-sm {
		padding: 4px 20px;
	}
}
button.cmn--btn {
	padding-top: 0;
	padding-bottom: 0;
	height: 50px;
	&:hover {
		background: var(--base-clr);
		color: var(--white-clr);
	}
}
.btn.cmn--btn {
	outline: none;
	box-shadow: none;
	&:hover {
		color: $title;
		border-color: rgba($title, 0.1);
	}
}

.btn__grp {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin: -4px;
	a {
		margin: 4px;
	}
}
