.dashboard-section {
	display: flex;
	background: var(--section-bg);
	.main--content {
		width: calc(100% - 260px);
		flex-grow: 1;
		.bg--gradient {
			top: 0;
			right: 0;
			position: absolute;
			content: "";
			opacity: 0.93;
			width: 100%;
			height: 100%;
			z-index: 1;
		}
	}
	.dashboard-sidebar {
		width: 100%;
		max-width: 260px;
		@include breakpoint(max-xl) {
			position: fixed;
			left: 0;
			@include transition($transition);
			top: 0;
			z-index: 9999;
			&:not(.active) {
				@include transform(translateX(-100%));
			}
		}
	}
	.breadcrumb {
		justify-content: flex-start;
		li {
			font-size: 14px;
		}
	}
	.breadcrumb-area {
		position: relative;
		z-index: 3;
	}
	.navbar-top {
		position: relative;
		z-index: 4;
		border-bottom: 1px solid rgba(var(--white-rgb), 0.1);
	}
}
@include breakpoint(xl) {
	.sidebar-close {
		display: none;
	}
	.navbar-top {
		min-height: 79px;
		align-items: center;
		display: flex;
	}
}
.breadcrumb-area {
	padding: 30px;
	.title {
		margin: 0;
	}
}
.dashboard-sidebar {
	height: 100vh;
	position: sticky;
	top: 0;
	background: url(./img/sidebar-bg.png) no-repeat center center / cover;
	> * {
		position: relative;
		z-index: 2;
	}
	.bg--gradient {
		top: 0;
		right: 0;
		position: absolute;
		content: "";
		opacity: 0.93;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
	.user-sidebar-header {
		display: flex;
		align-items: center;
		padding: 15px 15px 20px;
		border-bottom: 1px solid rgba(var(--white-rgb), 0.1);
		a {
			display: block;
			width: calc(100% - 30px);
			padding-right: 25px;
			img {
				height: 43px;
				width: 100%;
				object-fit: contain;
			}
		}
		.sidebar-close {
			position: relative;
			width: 30px;
			height: 30px;
			cursor: pointer;
			&::before,
			&::after {
				width: 25px;
				height: 2px;
				position: absolute;
				content: "";
				top: 50%;
				left: 50%;
				@include transform(translate(-50%, -50%) rotate(45deg));
				background: $danger-color;
			}
			&::after {
				@include transform(translate(-50%, -50%) rotate(-45deg));
			}
		}
	}
	overflow: hidden;
	&-inner {
		overflow-y: auto;
		height: 100vh;
	}
}
.dashboard-header-profile {
	position: relative;
	display: flex;
	align-items: center;
	z-index: 9;
	img {
		width: 40px;
		height: 40px;
		object-fit: cover;
		@include border-radius(50%);
		margin-right: 10px;
	}
	font-family: var(--heading-fonts);
	font-weight: 500;
	font-size: 14px;
	color: var(--white-clr);
	&:hover {
		color: var(--white-clr);
	}
}
.user-sidebar-body {
	padding: 30px 0;
	.user-sidbar-link {
		li {
			.subtitle {
				color: var(--white-clr);
				font-size: 14px;
				padding-left: 10px;
			}
			a {
				padding: 8px 20px;
				color: var(--light-clr);
				display: flex;
				i {
					color: var(--base-clr);
				}
				font-size: 14px;
				.icon {
					width: 22px;
					display: inline-block;
				}
				&.active {
					border-left: 3px solid var(--base-clr);
				}
				&:hover {
					padding-left: 24px;
					background: rgba(var(--white-rgb), 0.051);
				}
			}
			&:not(:first-child) {
				.subtitle {
					display: block;
					margin-top: 25px;
				}
			}
		}
	}
}
body {
	*::-webkit-scrollbar {
		width: 5px;
	}
	*::-webkit-scrollbar-thumb {
		background: var(--base-clr);
		background: rgba(var(--white-rgb), 0.4);
		border-radius: 3px;
	}
}
.user-toggle-menu {
	position: absolute;
	top: calc(100% + 40px);
	right: 0;
	padding: 20px;
	@include border-radius(5px);
	background: var(--section-bg);
	min-width: 200px;
	font-size: 13px;
	border: 1px solid var(--border-clr);
	z-index: 99;
	@include breakpoint(max-xl) {
		top: calc(100% + 27px);
	}
	&::before {
		content: "";
		width: 30px;
		height: 30px;
		@include transform(rotate(45deg));
		border: 1px solid var(--border-clr);
		display: block;
		position: absolute;
		top: -15px;
		background: var(--section-bg);
		right: 20px;
		border-right: none;
		border-bottom: none;
		@include breakpoint(max-sm) {
			right: 12px;
		}
	}
	ul {
		li {
			&:not(:last-child) {
				margin-bottom: 3px;
			}
			a {
				color: var(--title-clr);
				display: flex;
				align-items: center;
				i {
					font-size: 14px;
					width: 25px;
					color: var(--base-clr);
				}
			}
		}
	}
	&:not(.active) {
		display: none;
	}
}
.dashborad--content {
	padding: 30px;
	@include breakpoint(max-sm) {
		padding: 30px 15px;
	}
	display: flex;
	flex-direction: column;
}
.dashboard-card {
	@include border-radius(5px);
	// box-shadow: 0 0 5px rgba(var(--title-rgb), 0.1);
	background: var(--body-bg);
	border: 1px solid var(--border-clr);
	display: block;
	color: var(--base-clr);
	position: relative;
	&:not(:last-child) {
		margin-bottom: 10px;
	}
	.deposit-btn-grp {
		display: flex;
		margin: -5px;
		a {
			display: block;
			margin: 5px;
			font-size: 12px;
			flex-grow: 1;
			width: calc((100% / 2) - 10px);
		}
	}
	&__header {
		display: flex;
		align-items: center;
		padding: 20px;
		&__icon {
			img {
				width: 56px;
				height: 56px;
				object-fit: contain;
			}
			font-size: 50px;
			color: var(--base-clr);
			line-height: 1;
		}
		&__cont {
			width: calc(100% - 65px);
			padding-left: 20px;
			.name {
				font-weight: 600;
				margin: 0;
			}
		}
		@media screen and (min-width: 768px) {
			@include breakpoint(max-xxl) {
				.dashboard-card__header__icon img {
					width: 55px;
					height: 55px;
				}
				.dashboard-card__header__cont {
					width: calc(100% - 55px);
					padding-left: 15px;
				}
				padding: 20px 15px;
			}
		}
	}
	&__content {
		padding: 15px 20px;
		background: rgba(var(--title-rgb), 0.031);
		@include border-radius(0 0 5px 5px);
		border-top: 1px solid var(--border-clr);
		h6 {
			font-weight: 400;
			font-size: 14px;
		}
		@media screen and (min-width: 768px) {
			@include breakpoint(max-xxl) {
				padding: 20px 15px;
			}
		}
	}
}
.dashboard--wrapper {
	margin: -10px;
	@extend %flex;
	.dashboard--width {
		padding: 10px;
		width: 320px;
		flex-grow: 1;
		@include breakpoint(md) {
			width: 100%;
			max-width: calc(100% / 3);
		}
		@media screen and (min-width: 1024px) {
			max-width: calc(100% / 4);
		}
	}
}
.dashboard-title {
	color: var(--base-clr);
	margin: 0;
	margin-bottom: 15px;
}
.dashboard--content-item {
	&:not(:last-child) {
		margin-bottom: 40px;
	}
	.social-icons li a {
		width: 50px;
		height: 50px;
		line-height: 50px;
		background: rgba(var(--title-rgb), 0.051);
		color: var(--title-clr);
		&:hover {
			background: var(--base-clr);
			color: var(--white-clr);
		}
	}
}
.create-offer-header {
	ul {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -12px -12px 0;
		font-family: var(--heading-fonts);
		cursor: pointer;
		li {
			span {
				padding: 0 30px 12px 25px;
				position: relative;
				&::before,
				&::after {
					position: absolute;
					content: "";
					@include border-radius(50%);
				}
				&::after {
					top: 3px;
					left: 0;
					width: 20px;
					height: 20px;
					box-shadow: 0 0 4px rgba(var(--title-rgb), 0.5);
					padding-left: 0;
				}
				&::before {
					top: 8px;
					left: 5px;
					width: 10px;
					height: 10px;
					background: var(--title-clr);
				}
			}
			&.active {
				span {
					&::before {
						background: var(--base-clr);
					}
				}
			}
		}
	}
}
.create-offer-wrapper {
	border: 1px solid var(--border-clr);
	padding: 30px;
	@include border-radius(5px);
	background: var(--body-bg);
	.create-offer-header {
		margin-bottom: 35px;
	}
	@include breakpoint(max-sm) {
		padding: 30px 20px;
	}
	.form-label {
		display: block;
	}
}
.action-type-wrapper {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -12px -20px;
	.form-check {
		margin: 0 12px 20px;
		// max-width: 400px;
		max-width: calc(50% - 24px);
		width: 100%;
		&-input {
			width: 30px;
			height: 30px;
		}
		h6 {
			margin: 0;
			margin-bottom: 10px;
			font-size: 16px;
		}
		p {
			margin: 0;
		}
		@include breakpoint(max-xl) {
			font-size: 14px;
			line-height: 1.6;
		}
		@include breakpoint(max-lg) {
			max-width: calc(50% - 24px);
		}
		@include breakpoint(max-sm) {
			max-width: 100%;
		}
		padding: 0;
	}
	.form-check-label {
		padding: 30px 20px;
		margin: 0;
		@include border-radius(5px);
		border: 1px solid var(--border-clr);
		display: block;
		height: 100%;
		position: relative;
		overflow: hidden;
		.checkmark {
			position: absolute;
			right: 15px;
			top: 15px;
			color: var(--base-clr);
			display: none;
		}
	}
	.form-check-input:checked ~ .form-check-label {
		background: var(--primary-clr);
		border-color: var(--primary-clr);
		color: var(--light-clr);
		.checkmark {
			animation: zoomIn 0.5s;
			-webkit-animation: zoomIn 0.5s;
			-moz-animation: zoomIn 0.5s;
			display: block;
		}
		h6 {
			color: var(--base-clr);
		}
	}
}
.sticky-deposit {
	position: sticky;
	top: 120px;
}

.chat__list {
	margin: 0;
	display: block;
	max-height: calc(100vh - 250px);
	min-height: 400px;
	overflow-y: scroll;
}

.chat__list > li {
	width: 100%;
	border-bottom: 1px solid #e1e1e1;
	padding: 10px 0;
}

.chat__list > li:last-child {
	border: none;
}

.chat__item {
	width: 100%;
}

.chat__item .item__inner {
	display: block;
	padding: 10px 15px;
	border-radius: 5px;
	margin-right: 14px;
}

.chat__item .item__inner .chat__meta {
	margin: 3px;
	margin-top: -25px;
	padding-left: 65px;
	font-size: 13px;
}

.chat__item .item__inner a {
	color: #456;
}

.chat__item.active .item__inner,
.chat__item:hover .item__inner {
	background: rgba(31, 31, 35, 0.05);
}
.chat__msg-header .post__creator {
	display: inline-flex;
	position: relative;
}

.chat__msg-header .post__creator .profile-link {
	position: absolute;
	content: "";
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
}
.msg__wrapper {
	padding: 0;
}
.chat__msg-body {
	max-height: calc(100vh - 250px);
	min-height: 400px;
	overflow-y: scroll;
	background: var(--section-bg);
}
.chat__msg-body .msg__wrapper li p {
	padding: 10px 15px;
	border-radius: 15px 15px 15px 0;
	font-size: 15px;
	display: inline-block;
}
.chat__msg-body .msg__wrapper li.incoming__msg p {
	background: rgba(var(--title-rgb), 0.2);
	color: var(--title-clr);
}
.out__msg {
	background: #206bc4 !important;
	color: #fff;
}

.chat__msg-footer .send__msg {
	box-shadow: 0 0 15px rgba(31, 31, 35, 0.05);
}

.chat__msg-footer .send__msg .input-group {
	position: relative;
}

.chat__msg-footer .send__msg .form--control {
	height: 50px;
	padding: 10px 15px;
	padding-left: 60px;
}

.chat__msg-footer .send__msg .send-btn {
	background: rgba(31, 31, 35, 0.05);
	width: 50px !important;
	height: 50px !important;
	padding: 0;
	font-size: 24px;
	color: #fff;
	background: #206bc4 !important;
}

.chat__msg-footer .send__msg .upload-file {
	cursor: pointer;
	position: absolute;
	left: 0px;
	top: 0px;
	bottom: 0;
	z-index: 11;
	width: 50px;
	height: 50px;
	background: var(--section-bg);
	font-size: 18px;
	border-radius: 5px 0 0 5px !important;
	border: 1px solid var(--border-clr);
}

.msg__item .comment-img {
	max-width: 300px;
	width: 100%;
}

.msg__item .comment-img img {
	width: 100%;
}
ul li {
	list-style: none;
}
.msg__item .post__creator-content {
	padding-top: 0;
}
.chat__msg-footer .send__msg .upload-file i,
.chat__msg-footer .send__msg .upload-file {
	text-align: center;
	line-height: 50px;
}
.send-btn {
	border-radius: 0 5px 5px 0;
	line-height: 50px;
}
.msg__item .post__creator-content .comment-date {
	display: block;
	font-size: 11px;
	margin-bottom: 7px;
}
.chat__msg-footer {
	margin-top: 20px;
}

.outgoing__msg .post__creator {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row-reverse;
}

.post__creator-content {
	max-width: 90%;
}
@media screen and (min-width: 768px) {
	.post__creator-content {
		max-width: 575px;
	}
}
.outgoing__msg .post__creator-content {
	text-align: right;
	padding-left: 18px;
	padding-right: 18px;
}
.incoming__msg .post__creator-content {
	padding-left: 18px;
	padding-right: 18px;
}

.outgoing__msg .post__creator-content p {
	border-radius: 15px 15px 0 15px !important;
}
body * ::-webkit-scrollbar {
	width: 5px !important;
	height: 5px;
}

body * ::-webkit-scrollbar-thumb {
	background: rgba(31, 31, 35, 0.4) !important;
	border-radius: 5px !important;
}
.msg__wrapper li {
	list-style: none;
	padding: 0;
}
.msg__wrapper li p {
	margin-bottom: 0;
}
.btn-primary {
	background: var(--primary-clr);
}
.chat__msg-header {
	border: none !important;
}
.chat__list {
	li {
		padding: 0 !important;
		border: none !important;
	}
	padding-right: 0;
}
.post__creator {
	.name {
		font-size: 18px;
	}
}
.chat__item.active {
	.item__inner {
		background: rgba(var(--title-rgb), 0.041);
		border-radius: 3px;
		margin: 0;
	}
}

.modal {
	.table-responsive {
		max-height: 70vh;
		overflow-x: hidden;
		overflow-y: auto;
		&::-webkit-scrollbar-track {
			box-shadow: inset 0 0 6px rgba(var(--title-rgb), 0.3);
			width: 4px;
		}
		&::-webkit-scrollbar {
			width: 4px;
		}
		&::-webkit-scrollbar-thumb {
			background-color: var(--base-clr);
			outline: 1px solid var(--base-clr);
			width: 4px;
		}
	}
	.modal-content {
		position: relative;
	}
}
.modal-xl {
	@include breakpoint(lg) {
		@include breakpoint(max-xl) {
			max-width: 960px;
		}
	}
	@include breakpoint(md) {
		@include breakpoint(max-lg) {
			max-width: 660px;
		}
	}
}
.badge {
	font-family: var(--heading-fonts);
	font-weight: 500;
	text-transform: capitalize;
	padding: 5px 10px;
	@include border-radius(15px);
}
.close-modal-btn {
	background: transparent;
	color: $danger-color !important;
	font-size: 24px;
	padding: 0;
	width: 40px;
	height: 40px;
	line-height: 40px;
	@include breakpoint(md) {
		position: absolute;
		right: 20px;
		top: 10px;
	}
}
.modal {
	background: rgba(var(--primary-rgb), 0.3);
}

.modal-content {
	box-shadow: var(--base-shadow);
	background: var(--body-bg);
}
.modal-title {
	display: flex;
	align-items: center;
	justify-content: center;
	&::after,
	&::before {
		content: "";
		width: 60px;
		height: 2px;
		background: var(--base-clr);
		display: inline-block;
	}
	&::after {
		margin-left: 5px;
	}
	&::before {
		margin-right: 5px;
	}
}
.badge.badge-sm {
	font-size: 10px !important;
	padding: 5px 7px !important;
}
.user-sidebar-body .user-sidbar-link li a {
	align-items: center;
}
.dashboard-header {
	background: url(./img/dashboard-bg.png) no-repeat center center / cover;
	.change-language {
		display: flex;
		align-items: center;
		font-size: 14px;
		.language {
			height: 32px;
			padding-left: 5px;
			border: 1px solid rgba($white, 0.1);
			border-radius: 4px;
			margin-left: 15px;
			outline: none;
			background: rgba($white, 0.051);
			color: $white;
			option {
				color: var(--title-clr);
				background: var(--section-bg);
			}
			&:focus {
				box-shadow: none;
			}
		}
		display: flex;
		align-items: center;
		@media screen and (max-width: 375px) {
			font-size: 12px;
			font-family: var(--heading-fonts);
			.language-bar {
				margin-left: 5px;
			}
		}
		&.text--title {
			.language-bar {
				color: $title;
				border-color: var(--border-clr);
			}
		}
	}
}

.breadcrumb-area {
	padding: 30px;
	.title {
		margin: 0;
	}
	.breadcrumb {
		margin-bottom: 0;
	}
}

.bg--gradient {
	background-image: linear-gradient(
		to left top,
		#00447a,
		#004072,
		#003b6b,
		#003763,
		#00335c
	);
}
