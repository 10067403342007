/*Blogs*/
.blog__item {
	position: relative;
	@include transition($transition);
	&-img {
		height: 260px;
		@include border-radius(5px 5px 0 0);
		overflow: hidden;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			@include transition($transition);
		}
		@include breakpoint(lg) {
			@include breakpoint(max-xxl) {
				height: 240px;
			}
		}
		.date {
			position: absolute;
			top: 20px;
			right: 20px;
			width: 80px;
			padding: 10px 15px;
			text-align: center;
			background: var(--dark-clr);
			color: var(--base-clr);
			font-size: 20px;
			line-height: 1.4;
			font-family: var(--heading-fonts);
			font-weight: 500;
			@include border-radius(5px);
			border: 1px solid var(--border-dark);
			span {
				display: block;
			}
		}
	}
	.blog-link {
		position: absolute;
		z-index: 1;
		inset: 0;
	}
	&-cont {
		padding: 25px 25px 30px;
		border-radius: 0 0 5px 5px;
		background: var(--section-bg);
		border: 1px solid var(--border-clr);
		border-top: none;
		&-title {
			font-size: 20px;
			line-height: 1.3;
			margin: 0;
			margin-bottom: 22px;
			color: var(--base-clr);
		}
		@media screen and (max-width: 420px) {
			padding: 25px 15px 30px;
		}
		@media screen and (min-width: 992px) and (max-width: 1199px) {
			padding: 25px 15px 30px;
		}
	}
	.blog__author {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		.author {
			display: flex;
			align-items: center;
			img {
				width: 40px;
				height: 40px;
				@include border-radius(50%);
				object-fit: cover;
			}
			h6 {
				font-size: 16px;
				font-weight: 500;
				color: var(--base-clr);
				padding-left: 7px;
				margin: 0;
			}
			width: calc(100% - 80px);
		}
		.read--more {
			width: 80px;
			text-align: right;
			text-decoration: underline;
		}
	}
	&:hover,
	&.active {
		@include border-radius(5px);
		box-shadow: 0 0 3px rgba(var(--base-rgb), 0.6),
			0 0 6px rgba(var(--base-rgb), 1);
		.blog__item-img {
			img {
				@include transform(scale(1.051));
			}
		}
	}
	&-details {
		box-shadow: unset !important;
		.blog__item-img {
			height: unset;
			img {
				@include transform(scale(1));
			}
		}
		.blog__details {
			p {
				margin-top: 0;
				&:not(:last-child) {
					margin-bottom: 25px;
				}
			}
			.subtitle {
				margin-top: 0;
				margin-bottom: 16px;
			}
		}
		.social-icons li i {
			background: rgba(var(--title-rgb), 0.1);
		}
	}
}
.bg--section {
	.blog__item-cont {
		background: var(--body-bg);
	}
}
.line--1,
.line--2,
.line--3,
.line--4 {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}
.line--1 {
	-webkit-line-clamp: 1;
}
.line--2 {
	-webkit-line-clamp: 2;
}
.line--3 {
	-webkit-line-clamp: 3;
}
.line--4 {
	-webkit-line-clamp: 4;
}
