$bootstrap-sass-asset-helper: false !default;

$title: #000;

$success-color: #0ead69;
$secondary-color: #668799;
$danger-color: #d90429;
$warning-color: #ee9b00;
$info-color: #00b4d8;
$primary-color: #0c67a3;
$ash-color: #fafafa;
$white: #fff;
$lallu-color: #e883ae;
$dark-color: #192a56;
$dark-bg: #131b32;
$light-color: #daddff;
$black: #000;
$section-bg-2: #fafafa;
$whatsapp: #25d366;
$facebook: #3b5998;
$twitter: #55acee;
$linkedin: #007bb5;
$pinterest: #007bb5;
$skype: #2fb7df;
$youtube: #cd201f;
$google: #dd4b39;
$camera: #e4405f;
$instagram: #e4405f;
$tumblr: #34465d;
$behance: #1769ff;
$star: #e9ba17;
$green: #0be881;
$green-h: #0fbcf9;
$red: #f53b57;
$yellow: #dec32b;
$nell: #212fb4;
$navy: #d8e0f3;
$placeholder: #a1a1a2;
$name-dec: #3f485d;
$futbol: #db3776;
$vimeo: #1ab7ea;
$dribble: #ea4c89;

$transition: all ease 0.3s;

$base2: #92278f;
$base3: #8dc63f;
$base4: #00bfff;

:root {
	--base-clr: #0066ff;
	--base-rgb: 0, 102, 255;
	--base-clr-2: #1131ca;
	--base-rgb-2: 17, 49, 202;
	--body-clr: #536479;
	--light-clr: #ded6ff;
	--title-clr: #000;
	--title-rgb: 0, 0, 0;
	--body-bg: #f3f9ff;
	--section-bg: #ffffff;
	--section-light: #f3f9ff;
	--dark-clr: #000;
	--dark-rgb: 0, 0, 0;
	--white-clr: #ffffff;
	--white-rgb: 255, 255, 255;
	--primary-clr: #0c67a3;
	--primary-rgb: 12, 103, 163;
	--border-clr: #e5e5e5;
	--border-light: #e5e5e5;
	--border-light-only: #e5e5e5;
	--border-dark: #302474;
	--border-dark-only: transparent;

	--heading-fonts: "Poppins", sans-serif;
	--body-fonts: "Roboto", sans-serif;
	--base-shadow-sm: 0 0 1px var(--base-clr), 0 0 3px var(--base-clr);
	--base-shadow: 0 0 1px var(--base-clr), 0 0 2px var(--base-clr),
		0 0 3px var(--base-clr), 0 0 5px var(--base-clr), 0 0 7px var(--base-clr);
	--shadow: 0 0 5px rgba(var(--title-rgb), 0.2);
}
